import { css } from 'styled-components'

// Map colors
export const green = {
  base: '#8DC63F',
  light: '#a4d266',
  dark: '#20C05D',
  left: '#DEE021',
  right: '#A4D264',
  extraDark: '#027A75',
}

export const orange = {
  200: '#FFDAB7',
  light: '#FF9F33',
  base: '#FF8700',
  extra: '#FF7A00',
  medium: '#EB7000',
  dark: '#FF500F',
  linear: 'linear-gradient(7.17deg, #FF500F 0%, #FF8700 100%)',
}

export const red = {
  base: '#FF2A44',
  light: '#ff5d71',
  dark: '#f6001e',
}

export const yellow = {
  base: '#FCEE21',
  light: '#fdf253',
  dark: '#e7d803',
  medium: '#ffaa00',
  extraDark: '#FFBA00',
}

export const blue = {
  base: '#007bff',
  dark: '#374456',
  extraDark: '#15415C',
  linear: 'linear-gradient(7.28deg, #194A69 0%, #072534 100%)',
}

export const white = '#FFFFFF'

export const gray = {
  100: '#f5f5f5',
  200: '#e7e7e7',
  300: '#dee2e6',
  400: '#F5F6FA',
  500: '#E6E7F0',
  550: '#C5C8D4',
  600: '#8C91A9',
  700: '#6A6E81',
  800: '#4B4E5C',
  900: '#212529',
  950: '#161616',
  base: '#58595b',
  light: '#747684',
  medium: '#4B4E5C',
  dark: '#292929',
}

export const grayscale = {
  100: '#F5F6FA',
  200: '#DEDFE4',
  300: '#B6B7BB',
  400: '#6A6C72',
  500: '#161616',
}

export const black = '#000000'
export const brown = '#3F3A3A'
export const indigo = '#6610f2'
export const purple = '#6f42c1'
export const pink = '#e83e8c'
export const teal = '#20c997'
export const cyan = '#17a2b8'
export const turquoise = '#36B29C'
export const blueMedium = '#194A69'

type TColor = {
  [name: string]: string;
}

export function generateColorClasses (colorMap: TColor, name: string) {
  return (
    Object.keys(colorMap).map((type: string) => (
      css`
        .bg-${name}--${type} { background: ${colorMap[type]}; }
        .text-${name}--${type}   { color: ${colorMap[type]}; }
        .fill-${name}--${type}   { fill: ${colorMap[type]}; }
      `
    ))
  )
}
