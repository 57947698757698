type TBreakpoints = {
  [name: string]: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
}

export const breakpoints: TBreakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1441px',
  xxxl: '1600px',
}

export const widths = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1600,
}

export const device = {
  mobile: `(min-width: ${breakpoints.sm})`,
  tablet: `(min-width: ${breakpoints.md})`,
  desktopLG: `(min-width: ${breakpoints.lg})`,
  desktopXL: `(min-width: ${breakpoints.xl})`,
  desktopXXL: `(min-width: ${breakpoints.xxl})`,
}

export default breakpoints
